<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4" v-if="!success">
              <CForm @submit="registration">
                <h1>Registration</h1>
                <p class="text-muted">Create your account</p>

                <CAlert
                  color="danger"
                  v-for="(error, index) in errors"
                  :key="index"
                  >{{ error }}</CAlert
                >

                <CInput
                  placeholder="Email"
                  v-model="email"
                  autocomplete="email"
                  prepend="@"
                />
                <CInput
                  placeholder="Password"
                  type="password"
                  v-model="password"
                  autocomplete="new-password"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>
                <CInput
                  placeholder="Repeat password"
                  type="password"
                  v-model="passwordConfirmation"
                  autocomplete="new-password"
                  class="mb-4"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>

                <CSpinner color="info" v-if="loading" />

                <CButton color="success" v-if="!loading" type="submit" block
                  >Create Account</CButton
                >
                <CButton color="link" type="button" to="login" block
                  >Back to Login</CButton
                >
              </CForm>
            </CCardBody>

            <CCardBody class="p-4" v-if="success">
              <h1>Registration</h1>
              <p class="">
                We sent you instruction to activate your account. Please check
                your email.
              </p>
              <CButton color="success" type="button" to="login" block
                >Back to Login</CButton
              >
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>


<script>
// import { mapState, mapGetters } from "vuex";
import auth from "@/api/auth";
export default {
  data() {
    return {
      errors: [],
      email: null,
      password: null,
      passwordConfirmation: null,
      success: false,
      loading: false,
    };
  },
  //   computed: mapState({
  //     user: (state) => state.auth.user,
  //     ...mapGetters({
  //       authStatus: "auth/authStatus",
  //       isLoggedIn: "auth/isLoggedIn",
  //     }),
  //   }),
  methods: {
    registration(e) {
      this.loading = true;
      this.errors = [];

      if (!this.email) {
        this.success = false;
        this.loading = false;
        this.errors.push("Email can't be blank");
      }

      if (!this.password) {
        this.success = false;
        this.loading = false;
        this.errors.push("Password can't be blank");
      }

      if (!this.passwordConfirmation) {
        this.success = false;
        this.loading = false;
        this.errors.push("Password confirmation can't be blank");
      }

      if (this.password !== this.passwordConfirmation) {
        this.success = false;
        this.loading = false;
        this.errors.push("Passwords do not match");
      }

      if (!this.errors.length) {
        auth
          .registration(this.email, this.password)
          .then(() => {
            this.success = true;
            this.loading = false;
          })
          .catch((error) => {
            this.success = false;
            if (error.response.data.errors) {
              this.errors = error.response.data.errors;
            }
            this.loading = false;
          });
      }

      e.preventDefault();
    },
  },
};
</script>
